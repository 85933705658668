import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Wrapper, SEO, Header } from '../components'
import website from '../../config/website'
import { LocaleContext } from '../components/Layout'
import theme from '../styles/theme'

const IndexWrapper = Wrapper.withComponent('main')
const Inner = styled.div`
  .gatsby-image-wrapper {
    max-width: 100% !important;
  }
  img {
    max-width: 100% !important;
    display: block;
  }
  p {
    margin: 1rem auto;
    font-family: "Lato" !important;
  }
  h2 {
    font-family: "Lato" !important;
    font-size: 2.4rem;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    color: #6d6d6d;
    text-transform: uppercase;
  }
  h3 {
    font-family: "Lato" !important;
    font-size: 1.4rem;
    margin: 0 0 2rem 0;
    font-weight: 300;
    color: #6d6d6d;
    text-transform: uppercase;
    span {
      font-weight: 500;
    }
    &.h2 {
      font-size: 1.8rem!important;
    }
  }
`;

const Hero = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  > div {
    width: 50%;
    &:first-of-type {
      padding-right: 1rem;
    }
    &:last-of-type {
      padding-left: 1rem;
    }
    p {
      margin-bottom: 1.35rem;
      font-size: 1.1rem;
      color: #6d6d6d;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
    > div {
      width: 100%;
      &:first-of-type {
        padding-right: 0;
      }
      &:last-of-type {
        padding-left: 0;
      }
      p {
        &:first-of-type {
          padding-top: 1.35rem;
        }
      }
    }
  }
`;

const Catalogs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  img {
    margin-bottom: 1rem;
    &:first-of-type {
      margin-right: .5rem;
    }
    &:last-of-type {
      margin-right: .5rem;
    }
  }

  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
    img {
      margin-top: 2rem;
    }
  }
`

const Shop = ({
  data,
  location,
  pageContext: {
    locale
  },
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const context = { slug: 'shop', parent: null };
  console.log(data)
  return (
    <React.Fragment>
      <SEO title={`${i18n.defaultTitleAlt} - Shop`} pathname={location.pathname} locale={locale} />
      <Header {...context} />

      <IndexWrapper id={website.skipNavId} style={{ paddingBottom: 0}}>
        <Inner>
          <Hero>
            <div style={{ width: '100%', paddingTop: '2rem', paddingLeft: 0, paddingRight: 0 }}>
              <p style={{ textAlign: 'center' }}>
                If you wish to receive the artowrks catalog, <br />please send your request to <a className="regular-link" href="mailto:davidbraud@dischr.com">davidbraud@dischr.com</a>.
              </p>

              <Catalogs>
                <img src={data.catalog.childImageSharp.fixed.srcWebp} alt={`Catalog 2023`} />
                <img src={data.catalog1.childImageSharp.fixed.srcWebp} alt={`Fonds d'atelier`} />
              </Catalogs>
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>
    </React.Fragment>
  )
}

export default Shop

export const pageQuery = graphql`
  query catalogs {
    catalog: file(name: {eq: "b9de880e-b96a-4271-a623-d351201c6bdd_Catalog_couv"}) {
      childImageSharp {
        fixed(width: 354, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    catalog1: file(name: {eq: "64853709-a00a-4ce9-9a00-5cf318333430_fonds_atelier"}) {
      childImageSharp {
        fixed(width: 354, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
